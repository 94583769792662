import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We've launched several ways for us all to keep in touch.`}</p>
    <p>{`Here's how to keep close tabs on what we’re doing and thinking about, with messages for both our users and our partners (governments, employers, program managers, and social impact organizations).`}</p>
    <ul>
      <li parentName="ul">{`Twitter - `}<a parentName="li" {...{
          "href": "http://www.twitter.com/payperks"
        }}>{`@PayPerks`}</a></li>
      <li parentName="ul">{`Facebook - `}<a parentName="li" {...{
          "href": "http://www.facebook.com/payperks"
        }}>{`Like us on Facebook`}</a></li>
      <li parentName="ul">{`LinkedIn - `}<a parentName="li" {...{
          "href": "http://www.linkedin.com/company/payperks"
        }}>{`Follow our company page`}</a></li>
    </ul>
    <p>{`See you online!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      